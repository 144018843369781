import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { ApplicationProvider } from './src/context/application-context';
// import { JahiaProvider } from './src/context/jahia-context';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

declare global {
  interface Window {
    _satellite: any;
    Appcues: any;
    AppcuesSettings: {
      enableURLDetection: true;
    };
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <React.StrictMode>
      <ApplicationProvider>{element}</ApplicationProvider>
    </React.StrictMode>
  );
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  if (typeof window._satellite !== 'undefined') {
    const routeChangeEventName = 'gatsbyRouteChange';
    window._satellite.track(routeChangeEventName);
  }
};

export const onClientEntry = () => {
  console.log('onClientEntry')
  // Check if Appcues is already loaded to avoid duplicate loading
  if (typeof window !== "undefined" && !window.Appcues) {
    const script = document.createElement("script");
    script.src = "https://fast.appcues.com/98879.js"; // Replace with your Appcues App ID
    script.async = true;
    script.onload = () => {
      console.log("Appcues script loaded!");
    };
    document.head.appendChild(script);
  }
};
