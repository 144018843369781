import { IGuestCertAddressFormDetails } from '../components/Sections/GuestCertAddressForm/GuestCertAddressFormInterface';
import { ISelectedSearhItems } from '../components/Sections/SearchWidget/SearchWidgetInterface';

//API URLs
export const GET_POSTS = `/posts`;

//local storage keys
export const HRC_TOKEN = 'HRC-Token';
export const HVC_TOKEN = 'HVC-Token';
export const PING_ACCESS_TOKEN = 'ping-token';
export const HVC_SSO_TOKEN = 'SSO-Token';
export const HVC_JAHIASSO_TOKEN = '{SSO-Token}';
export const JAHIA_TOKEN = 'JAHIA-Token';
export const LOGIN_TYPE = 'Login-Type';
export const HRC = 'hrc';
export const HVC = 'hvc';
export const REVELEX_HRC = 'HRC';
export const REVELEX_HVC = 'HVC';
export const USER_PERSONA = 'user-persona';
export const LOGIN_REDIRECT = 'login-redirect';

//Dashboard
export const USER_BRAND_HRC = 'HRC';
export const USER_BRAND_HVC = 'HVC';

//CSS
export const HEADING1 = 'heading1';
export const HEADING2 = 'heading2';
export const HEADING3 = 'heading3';
export const HEADING4 = 'heading4';
export const HEADING5 = 'heading5';

//Process Data
export const OWNER_TYPE_LEGACY = 'LEGACY';
export const OWNER_TYPE_PORTFOLIO = 'PORTFOLIO';
export const OWNER_TYPE_HYBRID = 'HYBRID';
export const OWNER_TYPE_PLATNIUM = 'PLATINUM';
export const OWNER_TYPE_DEEDED_FIXED = 'DEEDED FIXED';
export const OWNER_TYPE_DEEDED_FLOAT = 'DEEDED FLOAT';
export const OWNER_TYPE_FRACTIONAL = 'FRACTIONAL';
export const OWNER_TYPE_AUDITIONS = 'AUDITIONS';
export const OWNER_TYPE_WHOLE = 'WHOLE';
export const HVO_OWNER = 'HVC OWNER';
export const NON_POINTS = 'non-Points';
export const TRUST = 'TRUST';
export const INDIVIDUAL = 'INDIVIDUAL';
export const HRPP = 'HRPP';
export const CUP = 'CUP';
export const RCUP = 'RCUP';
export const LCUP = 'LCUP';
export const EEE = 'EEE';
export const PORTFOLIO_RESTRICTED = 'PORTFOLIO_RESTRICTED';
export const PORTFOLIO_BANKED = 'PORTFOLIO_BANKED';
export const PORTFOLIO_BANKED_RESTRICTED = 'PORTFOLIO_BANKED_RESTRICTED';
export const PORTFOLIO_BONUS = 'PORTFOLIO_BONUS';
export const PORTFOLIO_BONUS_RESTRICTED = 'PORTFOLIO_BONUS_RESTRICTED';
export const SERVICE_ADJUST_POINTS = 'SERVICE_ADJUST_POINTS';

export const POINTS_COMPONENT_PERSONA_LIST = [
  OWNER_TYPE_LEGACY,
  OWNER_TYPE_PORTFOLIO,
  OWNER_TYPE_HYBRID,
  OWNER_TYPE_PLATNIUM,
];
export const PERSONA_BASED_BANK_POINT_ALER_LINK_PLACE_HOLDER = '{persona_based_link}';
export const SSO_TOKEN_HOLDER = '{0}';
export const NEXT_ALLOTMENT_DATE_PLACE_HOLDER = '{next_allotment_date}';
export const MONTHS = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

export const CONTRACT_NUM_PLACEHOLDER = '<ContractNumber>';
export const AMOUNT_PLACEHOLDER = '{amount}';
export const DUE_DATE_PLACEHOLDER = '{due-date}';

export const HVC_RESIDENCE_TYPES = [
  'One Villa Place Residences, El Corazon',
  'Main Street Station, Mountain Lodge, Park Hyatt Beaver Creek, Siesta Key',
]; //Jahia

export const PLATINUM_POINTS = 'Platinum Points';

export const JAHIA_DATA_STORAGE_KEY = 'jahia-data';
export const PROPERTIES_DATA_STORAGE_KEY = 'properties-data';
export const PROPERTY_DETAIL_DATA_STORAGE_KEY = 'property-detail-data';
export const CLUBS_DATA_KEY = 'clubs-data';

export const JAHIA_BASE_KEY = 'owners_consolidated.home';
export const JAHIA_BASE_KEY_MAINTANENCE_FEE = 'owners_consolidated';
export const JAHIA_BASE_KEY_THIRD_PARTY = 'owners_consolidated.third_party_modal_content';
export const JAHIA_BASE_KEY_ERROR_DATA = 'owners_consolidated.error';
export const JAHIA_BASE_GENERIC_IMAGE_KEY = 'owners_consolidated.generic_images';

export const JAHIA_BEYOND_KEY = 'owners_consolidated.beyond';
export const JAHIA_INTERVAL_KEY = 'owners_consolidated.interval_international';
export const JAHIA_RESORT_KEY = 'owners_consolidated.destinations';
export const JAHIA_RESORT_DETAILS_KEY = 'owners_consolidated.resort_details';
export const JAHIA_RESERVATION_KEY = 'owners_consolidated.managereservations';
export const JAHIA_FOOTER_KEY = 'owners_consolidated.footer';
export const JAHIA_HEADER_KEY = 'owners_consolidated.header';
export const JAHIA_GLOBAL_ALERT_KEY = 'owners_consolidated.alert_notifications';
export const JAHIA_MY_RESERVATION_KEY = 'owners_consolidated.my_reservations';
export const JAHIA_ACCOUNT_INFORMATION_KEY = 'owners_consolidated.account_information';
export const JAHIA_OWNER_RESOURCES_KEY = 'owners_consolidated.owner_resources';
export const JAHIA_TRAVELEX_KEY = 'owners_consolidated.travel_insurance';
export const JAHIA_CONTACTUS_KEY = 'owners_consolidated.contact_us';
export const JAHIA_TRAVELEX_ENROLL_KEY = 'owners_consolidated.travel_insurance_form';
export const JAHIA_POINTS_OVERVIEW_KEY = 'owners_consolidated.points_overview';
export const JAHIA_TRAVELEX_FAILURE_KEY = 'owners_consolidated.travel_insurance_failure';
export const JAHIA_TRAVELEX_SUCCESS_KEY = 'owners_consolidated.travel_insurance_success';
export const JAHIA_OWNER_EDUCATION_KEY = 'owners_consolidated.owner_education';
export const JAHIA_THIRD_HOME_KEY = 'owners_consolidated.third_home';
export const JAHIA_OWNER_CRUISES_KEY = 'owners_consolidated.owner_cruises';
export const JAHIA_DOCUMENTS_CENTER_KEY = 'owners_consolidated.documents_center';
export const JAHIA_WOH_KEY = 'owners_consolidated.world_of_hyatt';
export const JAHIA_CONVERT_POINTS_KEY = 'owners_consolidated.manage_points';
export const JAHIA_ONE_TRUST_KEY = 'owners_consolidated.one_trust_form_pages';
export const JAHIA_SEARCH_WIDGET_KEY = 'owners_consolidated.search_widget';
export const JAHIA_SEARCH_HUB_KEY = 'owners_consolidated.search_hub';
export const JAHIA_SEARCH_RESULT_KEY = 'owners_consolidated.search_result';
export const JAHIA_BOOKING_FLOW_KEY = 'owners_consolidated.booking_flow';
export const JAHIA_CATEGORIES_KEY = 'owners_consolidated.category_master_list';
export const JAHIA_ACCESSIBILITY_KEY = 'owners_consolidated.accessibility_category_master_list';
export const JAHIA_RESERV_CANCELLATION_POLICY_KEY = 'owners_consolidated.reservation_cancellation_policy';
export const JAHIA_JAUNTS_LANDING_PAGE_KEY = 'owners_consolidated.jaunts';
export const JAHIA_PAYMENT_PAGE_KEY = 'owners_consolidated.payment';
export const JAHIACANCEL_RESERV_PAGE_KEY = 'owners_consolidated.my_reservations.cancel_reservation';

export const DASHBOARD = 'dashboard';
export const VACATION = 'vacation';
export const BEYOND = 'beyond';
export const MORE_TO_OWNERSHIP = 'more_to_ownership';
export const OFFER = 'offer';
export const LEARNING = 'learning';
export const NEWS = 'news';

export const MAX_NEWS_COUNT = 1;
export const RESORT_ID = '{resort_id}';
/* Defined the applicable beyond & interval international categories in an array */
/* Total Points =Portfolio + Portfolio_restrictted+Portfolio_bank+ Portfolio_bank_restricted+ Portfolio_bonus+ Portfolio_bonus_restricted. */
export const BEYOND_INTERVAL_CATEGORIES = [
  OWNER_TYPE_PORTFOLIO,
  PORTFOLIO_RESTRICTED,
  PORTFOLIO_BANKED,
  PORTFOLIO_BANKED_RESTRICTED,
  PORTFOLIO_BONUS,
  PORTFOLIO_BONUS_RESTRICTED,
  /* below Points are commented because of wrong calculation */
  // HRPP,
  // CUP,
];

// INTERVEL INTERNATIONAL CALCULATIONS
export const INTERVAL_CATEGORIES = [OWNER_TYPE_PORTFOLIO, PORTFOLIO_BANKED, PORTFOLIO_BONUS, HRPP, CUP, EEE];

/* Hybrid: Portfolio + Portfolio_bank+ Portfolio_bonus+HRRP[Fixed]+HRRP[FLoat]+CUP+EEE */
export const INTERVAL_HYBRID_CATEGORIES = [OWNER_TYPE_PORTFOLIO, PORTFOLIO_BANKED, PORTFOLIO_BONUS, HRPP, CUP, EEE];

/* BEYOND: Portfolio + Portfolio_restrictted+Portfolio_bank+ Portfolio_bank_restricted+ Portfolio_bonus+ Portfolio_bonus_restricted. */
export const INTERVAL_BEYOND_CATEGORIES = [];

/* Legacy: HRRP[Fixed]+HRRP[FLoat]+CUP+EEE */
export const INTERVAL_LEGACY_CATEGORIES = [HRPP, CUP, EEE];

/* Portfolio: Portfolio + Portfolio_bank+ Portfolio_bonus */
export const INTERVAL_PROTFOLIO_CATEGORIES = [OWNER_TYPE_PORTFOLIO, PORTFOLIO_BANKED, PORTFOLIO_BONUS];

export const HEADER_HOME = 'home';
export const HEADER_RESERVATIONS = 'reservations';
export const HEADER_OWNER = 'owner';
export const HEADER_RESORTS = 'resorts';
export const HEADER_ACCOUNT = 'account';

export const RESORT_MOBILE_PLACEHOLDER_MENU = ['all_resorts', 'home_resorts', 'beyond_programme', 'secondary_section'];

export const DATE_DISPLAY_FORMAT_WITH_YEAR = 'dddd, MMM. DD, YYYY';
export const DATE_DISPLAY_FORMAT_WITH_FULL_DAY_MON_YEAR = 'dddd, MMMM DD, YYYY';
export const DATE_DISPLAY_FORMAT_WITHOUT_YEAR = 'dddd, MMM. DD';
export const DATE_DISPLAY_FORMAT_NO_DAY_WITH_YEAR = 'MMM. DD, YYYY';
export const DATE_DISPLAY_FORMAT_NO_DAY_WITHOUT_YEAR = 'MMM. DD';
export const DATE_DISPLAY_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT_MONTH_DATE_YEAR = 'MMM D, YYYY';
export const DATE_DISPLAY_FORMAT_NO_DAY_WITH_YEAR_FULL_MNTH = 'MMMM. DD, YYYY';
export const DATE_DISPLAY_FORMAT_CHECKIN = 'ddd, MMM DD';
export const DATE_DISPLAY_FORMAT_ONLY_MONTH_DT = 'MMM DD';
export const DATE_DISPLAY_FORMAT_ONLY_DT_YR = 'DD, YYYY';
export const DATE_DISPLAY_FORMAT_ONLY_MNTH_DT_YR = 'MMM DD, YYYY';
export const DATE_DISPLAY_FORMAT_WITH_YEAR_VILLA = 'ddd, MMM DD, YYYY';

export const TRANSACTION_HISTORY_CONTRACT = 'contract';
export const TRANSACTION_HISTORY_TRANSACTION_TYPE = 'transactionType';
export const TRANSACTION_HISTORY_TRANSACTION_DATE = 'date';
export const RESERVATION_BOOKED_STATUS_HRC = 'BOOKED';
export const RESERVATION_BEYOND_BOOKED_STATUS_HRC = 'Reserved';
export const RESERVATION_BOOKED_STATUS_HVC = 'Active';
export const RESERVATION_BEYOND_BOOKED_STATUS_HVC = 'Completed';

// pointsForTransferAPI's
export const POINTS_FOR_TRANSFER_ANNUAL_CONVERSION = 'pointsForTransfer?pt=ANNUAL_CONVERSION';
export const POINTS_FOR_TRANSFER_EEE = 'pointsForTransfer?pt=EEE';

export const EXPAND_CTA = 'Expand';
export const COLLAPSE_CTA = 'Collapse';

export const ERROR_STATE = 'error';
export const API_ERROR_STATE = 'api-error';
export const LOGGEDIN_IDS = 'loggedin-ids';
export const HVC_NOT_PROTECTED_STATUS = 'Not Protected';
export const HVC_PROTECTED_STATUS = 'Protected';
export const HVC_PARTIAL_PROTECTED_STATUS = 'Partial';

// defining API keys
export const PROFILE_KEY = 'PROFILE';
export const NOTIFICATIONS_KEY = 'NOTIFICATIONS';
export const CONTRACTS_KEY = 'CONTRACTS';
export const PREFERENCES_KEY = 'PREFERENCES';
export const RESERVATIONS_KEY = 'RESERVATIONS';
export const HVC_RULES_KEY = 'HVC_RULES';
export const HVC_CONTRACT_KEY = 'HVC_CONTRACT';
export const HVC_USAGE_KEY = 'HVC_USAGE';
export const HVC_MF_OWNER_CONTRACT_KEY = 'HVC_MF_OWNER_CONTRACT_KEY';
export const COUNTRY_KEY = 'COUNTRIES';

export const TRAVELEX_MAX_COVERAGE_YEARS = 4;
export const IMAGE_PATH_NOT_FOUND = 'Path Not Found!';

//BEYOND RESERVATIONS
export const CRUISE = 'CRUISE';
export const TOUR = 'TOUR';
export const HOTEL = 'HOTEL';

//Err pagetype
export const MFEE_ERROR = 'mferror';
export const HOPPER_ERROR = 'hopperError';
export const MAKE_RESERVATION_ERROR = 'makereservationerror';
export const CANCEL_RESERVATION_ERROR = 'cancelreservationerror';

//Document Center
export const CURRENT_YEAR_JAHIA_KEY = 'curr_year';
export const PREV_YEAR_JAHIA_KEY = 'prior_year';

//Destination credit
export const DC_SLUG = 'destination_credit';

//URL Params
export const CSP_KEY = 'shadow-session';
export const HRC_TOKEN_KEY = 'hrc-token';
export const PING_TOKEN_KEY = 'ping-token';

//WOH
export const WOH_REGISTER = 'WOH-REGISTER';
export const RESTRICT_POINT_CONVERSION_WOH_PERSONAS = [
  OWNER_TYPE_DEEDED_FIXED,
  OWNER_TYPE_DEEDED_FLOAT,
  OWNER_TYPE_FRACTIONAL,
];
export const WOH_CONVERT_LINK = '/world-of-hyatt/convert-points';
export const ACCRUE_LANDING_PAGE = '/accrue-points';

//Search Widget
export const TABS = {
  WHERE: 'WHERE',
  WHEN: 'WHEN',
  VILLA_GUESTS: 'VILLA-GUESTS',
  CHECKIN: 'CHECKIN',
  CHECKOUT: 'CHECKOUT',
};
export const FLEXIBLEDATES_TAB = 'FLEXIBLE';
export const EXACTDATES_TAB = 'EXACT';
export const EXPERIENCE_COLLECTION = 'EC';
export const STAE_KEY = 'state';
export const LOCATOR_KEY = 'location';
export const NAME_KEY = 'name';
export const IMAGE_KEY = 'image';
export const LONGITUDE_KEY = 'longitude';
export const LATITUDE_KEY = 'latitude';
export const PROPERTY_TAGS = 'tags';
export const PROPERTY_IMAGE = 'prop-image';
export const RESORT_TAG = 'resort-tag';
export const ADULTS = 'adults';
export const CHILDREN = 'children';

export const PAST_VACATION = 'past-vacation';
export const RECENTLY_VIEWED = 'recently-viewed';
export const JAUNTS = 'jaunts';
export const OTHER_CATEGORY = 'other';
export const UPTOLABEL = 'up to';
export const THELABEL = 'the';
export const RESORTS_LABEL = 'resort(s)';
export const CHECK_AVAILIBILITY = 'checkavail';
export const PROPERTY_TYPE_CHECK = 'checkbox';
export const STORAGE_KEY_PREV_VIEWED_RESORTS = 'viewed-resorts';
export const SEARCH_WIDGET_STORED_DATA_KEY = 'search-widget-stored-data';

// Search Widget collection name
export const PLATINUM_PROGRAM_DATA = 'PLATINUM PROGRAM';
export const EXPERIENCE_COLLECTION_DATA = 'EXPERIENCES COLLECTION';

export const initializedSelectedSearchItems: ISelectedSearhItems = {
  where: null,
  when: null,
  checkin: null,
  checkout: null,
  exactPayLoad: null,
  filter: null,
  villa_guests: null,
};
export const AVOID_SLUG_DUPLICATE_WIDGET = ['search-hub', 'book-vacation', 'home'];
export const WIDGET_IDENTIFIER_RESULT = 'result';
export const WIDGET_IDENTIFIER_HUB = 'hub';

export const FILTER_POINTS_TYPE = 'pointstype';
export const FILTER_POINTS_AMOUNT = 'pointsamount';
export const SORTING_ID = 'sort';
export const SORTING_OPTIONS = {
  ALPHA_ASCENDING: 'ALPHA_ASCENDING',
  ALPHA_DESCENDING: 'ALPHA_DESCENDING',
  EARLY_CHECKIN: 'EARLY_CHECKIN',
  POINTS_ASCENDING: 'POINTS_ASCENDING',
  POINTS_DESCENDING: 'POINTS_DESCENDING',
  LOCATION_ASCENDING: 'LOCATION_ASCENDING',
  LOCATION_DESCENDING: 'LOCATION_DESCENDING',
};
export const ASCENDING = 'asc';
export const DESCENDING = 'desc';
export const BOOKING_PAGE_NAME = {
  RESULT: 'RESULT',
  VILLA: 'VILLA',
  APPLY: 'APPLY',
  RESERVATION: 'RESERVATION',
  REVIEW: 'REVIEW',
};
export const MINUTES = 'minutes';
export const AND = 'and';
export const SECONDS = 'seconds';
export const TRAVEL_INS_UI_FLAG = {
  COVERED: 'COVERED',
  PARTIAL: 'PARTIAL',
  UNCOVERED: 'UNCOVERED',
  NOSELECTION: 'NOSELECTION',
};
export const ISTRAVELEX = {
  covered: 'covered',
  selected: 'selected',
  notselected: 'notselected',
};
export interface IAddGuestForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  firstNameErr: boolean;
  lastNameErr: boolean;
  emailErr: boolean;
  phoneErr: boolean;
  isError: boolean;
}
export const initialguestDetailState: IAddGuestForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  firstNameErr: false,
  lastNameErr: false,
  emailErr: false,
  phoneErr: false,
  isError: false,
};
export const initialStateBookingFlowEdit = {
  isSearchResultEdit: false,
  isVillaEdit: false,
  isAypEdit: false,
  isGuestEdit: false,
};
export const initialGuestCertAddressFormState: IGuestCertAddressFormDetails = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  streetAddress: '',
  countryIndex: undefined,
  country: '',
  countryCode: '',
  stateCode: '',
  stateIndex: undefined,
  state: '',
  city: '',
  zipCode: '',
  acknowledgement: false,
  isAcknowledgement: false,
  lastChangeIsErr: false,
};
export const GUEST_KEYS = {
  NAME: 'name',
  ADDRESS: 'address',
  PHONE: 'phone',
  EMAIL: 'email',
  OWNERID: 'ownerid',
  LASTNAME: 'Last Name',
  FIRSTNAME: 'First Name'
};
export const UNAUTHORIZED = 'Unauthorized';
export const NIGHTUSAGETYPE = 'Fixed/Float/Fractional';
